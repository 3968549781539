import React from 'react';
import classNames from 'classnames';
import * as classes from './pill.module.css';
import { kebabCase } from '../../utils/kebab-case';

export enum PillVariant {
  BOLD = 'bold',
  MODEST = 'modest',
}

export interface PillProps {
  className?: string;
  children: string;
  variant: PillVariant;
}

type Classes = keyof typeof classes;
const isKnownKeyword = (keyword: string | Classes): keyword is Classes =>
  Object.prototype.hasOwnProperty.call(classes, keyword);

const useStyleClass = (keyword: string, variant: PillVariant) => {
  if (variant === PillVariant.MODEST) {
    return 'text-left text-main pl-0';
  }
  if (isKnownKeyword(keyword)) {
    return `${classes[keyword]} py-1`;
  }
  return 'bg-main text-white py-1';
};

export const Pill: React.FC<PillProps> = ({ children, className, variant }) => {
  const keyword = kebabCase(children.toLowerCase())!;
  const styles = useStyleClass(keyword, variant);
  return (
    <div
      className={classNames(
        classes.pill,
        'inline-block',
        'px-4',
        'font-medium',
        'hover:opacity-75',
        styles,
        className,
      )}
    >
      {children}
    </div>
  );
};
