// extracted by mini-css-extract-plugin
export var react = "pill-module--react--1Fey8";
export var redux = "pill-module--redux--JH97x";
export var javascript = "pill-module--javascript--1vB-H";
export var typescript = "pill-module--typescript--1wiH6";
export var html = "pill-module--html--bcm0c";
export var css = "pill-module--css--3Rqg2";
export var node = "pill-module--node---tq8-";
export var angular = "pill-module--angular--3YNfe";
export var rxjs = "pill-module--rxjs--XJsug";
export var pill = "pill-module--pill--2hOJ-";