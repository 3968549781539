import React, { PropsWithChildren, ReactElement } from 'react';
import { Link } from 'gatsby';
import { Pill, PillProps } from './pill';

export interface PillsProps<Item> {
  ariaLabel: string;
  getId: (item: Item) => string | number;
  getLink: (item: Item) => string;
  getText: (item: Item) => string;
  items: Item[];
  variant: PillProps['variant'];
  header?: string;
  className?: string;
}

export const Pills = <Item extends object | string>({
  ariaLabel,
  getId,
  getLink,
  getText,
  items,
  variant,
  header,
  className = '',
}: PropsWithChildren<PillsProps<Item>>): ReactElement | null => {
  if (items.length === 0) {
    return null;
  }

  return (
    <section aria-label={ariaLabel} className={`text-xs gap-2 flex flex-row ${className}`}>
      {header && <span>{header}</span>}
      {items.map((item: Item) => (
        <Link to={getLink(item)} key={getId(item)}>
          <Pill variant={variant}>{getText(item)}</Pill>
        </Link>
      ))}
    </section>
  );
};
