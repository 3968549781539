import React from 'react';
import { CategoryModel } from '../models';
import { kebabCase } from '../utils/kebab-case';
import { Pills, PillsProps } from './pills';
import { PillVariant } from './pills/pill';

interface CategoryListProps {
  categories: CategoryModel[];
}

const spec: Pick<PillsProps<CategoryModel>, 'getId' | 'getLink' | 'getText'> = {
  getId: ({ category }) => category,
  getLink: ({ category }) => `/blog/categories/${kebabCase(category)}`,
  getText: ({ category, count }) => `${category} (${count})`,
};

export const CategoryList: React.FC<CategoryListProps> = ({ categories }) => (
  <Pills
    ariaLabel="Post series"
    header="Post series:"
    items={categories}
    variant={PillVariant.MODEST}
    {...spec}
  />
);
