import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { PostCard } from '../../components/post-card';
import { Alert, AlertVariant } from '../../components/alert';
import SEO from '../../components/seo';
import { Mdx, MdxEdge } from '../../generated/graphql.d';
import { DeepRequired } from '../../utils/deep-required';
import { PostCardContainer } from '../../components/post-card/post-card-container';
import { CategoryModel } from '../../models';
import { CategoryList } from '../../components/category-list';
import HeroLayout from '../../components/layouts/hero-layout';
import { HeroElement } from '../../components/layouts';

interface Query {
  allMdx: {
    edges: MdxEdge[];
    categories: CategoryModel[];
  };
}

const useBlogPosts = () => {
  const {
    allMdx: { edges, categories },
  } = useStaticQuery<Query>(graphql`
    query {
      allMdx(
        filter: { frontmatter: { hidden: { ne: true } } }
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD MMM YYYY")
              title
              description
              tags
              category
              thumbnail {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 48, height: 48)
                }
              }
              hackerNews
            }
            excerpt(pruneLength: 160)
            timeToRead
          }
        }
        categories: group(field: frontmatter___category) {
          count: totalCount
          category: fieldValue
        }
      }
    }
  `);
  const posts = edges.map(
    (edge) => edge.node as DeepRequired<Pick<Mdx, 'id' | 'frontmatter' | 'fields' | 'timeToRead'>>,
  );
  return { posts, categories };
};

export const BlogPage: React.FC = () => {
  const { posts, categories } = useBlogPosts();

  const hero = (
    <HeroElement
      title="Blog"
      description="A bit of poetry about coding - posts, tutorials and live coding"
    />
  );
  return (
    <HeroLayout hero={hero}>
      <SEO
        title="Blog"
        description="Collection of articles and blog posts written by Piotr Staniów"
      />
      <CategoryList categories={categories} />
      {posts.length > 0 ? (
        <PostCardContainer>
          {posts.map(({ id, frontmatter, fields: { slug }, timeToRead }) => (
            <PostCard key={id} {...frontmatter!} path={slug} timeToRead={timeToRead} />
          ))}
        </PostCardContainer>
      ) : (
        <Alert
          title="No posts were published yet"
          description="There is a million things I haven't written - But just you wait, just you wait 😉"
          variant={AlertVariant.INFO}
        />
      )}
    </HeroLayout>
  );
};

export default BlogPage;
